/* Create a common style for the phone frame */
.phone {
    width: 230px; /* You can adjust the width to fit your mockup design */
    height: 340px; /* You can adjust the height to fit your mockup design */
    border: 5px solid black;
    margin: 20px; /* Add some space between the two phone mockups */
    display: inline-block;
    border-radius: 6px;
    overflow: hidden;
}

/* Landscape orientation */
.phone.landscape {
    /*transform: rotate(90deg);*/
    width: 540px; /* You can adjust the width to fit your mockup design */
    height: 360px; /* You can adjust the height to fit your mockup design */
    
}

/* Portrait orientation */
.phone.portrait {
    /* You can style the portrait orientation if needed */
}

img.room_unit { height: 200px; }