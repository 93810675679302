.floor-plan-container {
    width: 100%;
    height: 500px;
    position: relative;

  }
  
  .floor-plan-svg {
    width: 670px;
    height: 100%;
    border: 10px black solid;
  }