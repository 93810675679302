.virtualPhone {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    color: white;
    font-size: 2vh;
}

.virtualPhoneHeader {
    background-color: var(--primaryColour);
    padding: 2px;
}

.virtualPhoneMain {
    flex: 1;
}

.virtualPhoneFooter {
    background-color: var(--primaryColour);
    padding: 2px;
}

.phone_call_container {
    display: flex;
    flex-direction: row;
}

.phone_calltype{ font-size: 4vh; }

.phone_call_body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.phone_timer{
    font-size: 3.5vh;
}

.phone_content {
    flex: 1;
}