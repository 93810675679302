.panel_call_type {
    
    height: 120px;
    padding: 10px;
}

.virtualPanel {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    font-size: 2vh;
}

.panelCallBody{
    display: flex;
    flex-direction: row;
}

.virtualPanelHeader {
    background-color: var(--primaryColour);
    padding: 2px;
    color: white;
}

.virtualPanelMain {
    flex: 1;
}


.virtualPanelFooter {
    background-color: var(--primaryColour);
    padding: 2px;
    color: white;
}

.panelCallFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.panel_call_body_content_room{
    font-size: 5vh;
}

.panel_call_body_content_zone{
    font-size: 2vh;
}

.panel_call_body_content_txcode{
    font-size: 1.5vh;
}

.panel_call_body_content_timer{
    font-size: 2vh;
}

.panel_call_body_content_accessorytype{
    font-size: 1.7vh;
}

.panel_call_body_content_calltype{
    font-size: 2.5vh;
}